'use strict';

require('./src/assets/scss/init.scss');
const ReactDOM = require('react-dom')

exports.onClientEntry = () => {};

// Based on https://github.com/gatsbyjs/gatsby/issues/17914
// There is some conflit between the JS on the server and
// client and React does reconciliation but fails to reconciliate
// href and src attribute
// Ideally, i would fix the discrepancy so that client matches server
exports.replaceHydrateFunction = () => {
    return (element, container, callback) => {
      ReactDOM.render(element, container, callback);
    };
  };