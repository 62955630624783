// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-gatsby-theme-blog-core-templates-post-query-js": () => import("./../../../src/gatsby-theme-blog-core/templates/post-query.js" /* webpackChunkName: "component---src-gatsby-theme-blog-core-templates-post-query-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-privacy-template-js": () => import("./../../../src/templates/privacy-template.js" /* webpackChunkName: "component---src-templates-privacy-template-js" */)
}

